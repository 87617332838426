import React from "react";
import Slider from "react-slick";
import Testimonial2 from "../../../assets/Images/Testimonial/Testimonial2.png";
import Testimonial3 from "../../../assets/Images/Testimonial/Testimonial3.png";
import Testimonial4 from "../../../assets/Images/Testimonial/Testimonial4.png";
import MSHubLogo from "../../../assets/Images/BrandLogo/MSHubLogo.png";

function PeopleSay() {
  const settings = {
    autoplay: false,
    autoplaySpeed: 1500,
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="spacing">
        <div className="peopleSayBgColor">
          <div className="container">
            <div className="titleFont text-center">
              {/* <h3>What People Say</h3> */}
           
            <img src={MSHubLogo} alt="Microsoft Start Hub Partner" className="img-fluid mx-auto WLogo" /> 
          
            </div>
            {/* <Slider {...settings}>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial2}
                />
                <div className="peopleDetails">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the standard
                    dummy text ever since the 1500s, when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book.
                  </p>
                  <h3>Ramisha Goenka</h3>
                  <h6>Web Designer</h6>
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial3}
                />
                <div className="peopleDetails">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the standard
                    dummy text ever since the 1500s, when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book.
                  </p>
                  <h3>Ramisha Goenka</h3>
                  <h6>Web Designer</h6>
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial4}
                />
                <div className="peopleDetails">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the standard
                    dummy text ever since the 1500s, when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book.
                  </p>
                  <h3>Ramisha Goenka</h3>
                  <h6>Web Designer</h6>
                </div>
              </div>
            </Slider> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default PeopleSay;
