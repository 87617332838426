import { useState, useEffect } from "react";
import axios from "axios"; // Import axios for making HTTP requests
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../AuthProvider";

const useForm = (validate) => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
    password2: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault(); // Ensure e is an event object and prevent default form submission behavior
    setErrors(validate(values));
    setIsSubmitting(true);
    if (Object.keys(errors).length > 0) {
      setErrors(validate(values));
      return;
    }
    try {
      const response = await axios.post("https://emplgateway.azurewebsites.net/dvs-service/api/isUserValid", {
        email: values.email,
        password: values.password,
        // Add other form fields here if needed
      });
      const userObject = {
        name: 'More, Ganesh', // Assuming the name is provided in the response
        email: values.email,
      };

      // Update user information in the context
      login(userObject);
      toast.success("Login Sucessfull");
      setValues({ email: "", password: "", password2: "" });
      setErrors({});
      navigate('/home');
      
    } catch (error) {
      // Handle error
      toast.error(error.response.data);
      
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      // Perform any action after successful form submission
      console.log("Form submitted successfully");
    }
  }, [errors, isSubmitting]);

  return { handleChange, handleSubmit, values, errors };
};

export default useForm;
