
export const OurPricingData= [
  {
    id: 1,
    title: "DVS Scrum Master",
    newPrice: "$TBC",
    oldPrice: "$TBC",
    subTitle: "MVP in Progress",
    contain1: "Facilitate scrum agile ceremonies and guide scrum teams to follow Agile and Scrum practices",
    contain2: "Keep track of blockers and project finances ",
    contain3: "Schedule connects with relevant parties to resolve blockers",
    contain4: "Guide scrum teams to follow Agile and Scrum practices",
    contain5: "Schedule connects with relevant parties to resolve blockers",
    contain6: "Send updates/actions from a meeting "    
  },
  {
    id: 2,
    title: "DVS Invest Assist",
    newPrice: "TBC",
    oldPrice: "TBC",
    subTitle: "Ideation Stage",
    contain1: "Provide investment support and education to clients and prospects",
    contain2: "Regular meetings with Active clients, converting the dormant clients into active",
    contain3: "Keep abreast of the market news financial trends & current affairs.",
    contain4: "Assist in preparing statements and reports",
    contain5: "",
    contain6: ""   
  },
  {
    id: 3,
    title: "DVS Career Counsellor",
    newPrice: "$200",
    oldPrice: "$250",
    subTitle: "Prototype Ready",
    contain1: "Handle scheduling, record-keeping and reporting",
    contain2: "Interact with parents to seek high level aspirations for their child",
    contain3: "Interact with child to seek their career interest",
    contain4: "Interactive data collection from parents and children",
    contain5: "Hosing (1 year Free)",
    contain6: "Domain (1 Year Free)"  
  },
 
];
export default OurPricingData;