import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ContactUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    message: "",
    subject: "Subscription to Newsletter"
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch("https://emplgateway.azurewebsites.net/dvs-service/request/saveContactRequest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        toast.success("Thanks for contacting us. You will hear from us soon!");
        // Optionally, reset the form after successful submission
        setFormData({
          name: "",
          email: "",
          companyName: "",
          message: ""
        });
      } else {
        
      
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error(error.response.data);
    }finally {
      setIsLoading(false);
    }
  };

  
  return (
    <>
      <div className="aboutUsBannerImg">
        <div className="container">
          <div className="contactUs">
            <Row>
              <Col xl={12} md={12} lg={12} sm={12}>
                <div>
                  <div className="titleFont">
                    <h3>Contact Us</h3>
                    <h2>Ready to Get Started?</h2>
                  </div>
                  <form
                    className="row g-3 needs-validation formContactus"
                    onSubmit={handleSubmit}
                  >
                    <div className="col-md-6  col-sm-6 col-6">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Name*"
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="E-mail*"
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        id="companyName"
                        placeholder="Company Name*"
                        required
                        value={formData.companyName}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        Please provide a valid Company Name.
                      </div>
                    </div>
                    <div className="col-md-12">
                      <textarea
                        type="text"
                        className="form-control"
                        id="message"
                        placeholder="Send Message*"
                        required
                        value={formData.message}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        Please provide a Send Message.
                      </div>
                    </div>

                    <div className="col-12">
                      <button className="button" type="submit" disabled={isLoading}>
                        {isLoading ? (
                          <>
                            <Spinner animation="border" size="sm" /> Submitting...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
