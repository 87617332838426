import React from 'react'
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import { useState } from "react";
import ab1 from '../../../assets/Images/steps/1.jpg';
import ab2 from '../../../assets/Images/steps/2.jpg';
import ab3 from '../../../assets/Images/steps/3.jpg';
import ab4 from '../../../assets/Images/steps/4.jpg'
const AboutSlider = () => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    return (
        <>  <div>
            <Row className="row-center">
                <Col >
                    <div className="eyeProImg">
                        <Slider
                            dots={false}
                            fade={true}
                            asNavFor={nav2}
                            arrows={false}
                            lazyLoad={true}
                            ref={(slider1) => setNav1(slider1)}
                            className="ab-fst_slider"
                        >
                            <div>
                                <img
                                    src={ab1}
                                    alt="product"
                                    className="img-fluid mx-auto"
                                />
                            </div>
                            <div>
                                <img
                                    src={ab2}
                                    alt="product"
                                    className="img-fluid mx-auto"
                                />
                            </div>

                            <div>
                                <img
                                    src={ab3}
                                    alt="product"
                                    className="img-fluid mx-auto"
                                />
                            </div> <div>
                                <img
                                    src={ab4}
                                    alt="product"
                                    className="img-fluid mx-auto"
                                />
                            </div>

                        </Slider>
                        <div>
                            <Slider
                                arrows={false}
                                asNavFor={nav1}
                                ref={(slider2) => setNav2(slider2)}
                                slidesToShow={3}
                                swipeToSlide={true}
                                focusOnSelect={true}
                                autoplay={false}
                                autoplaySpeed={1500}
                                infinite={true}
                                speed={500}
                                className="scnd_slider"
                            >
                                <div>
                                    <img
                                        src={ab1}
                                        alt="product"
                                        className="img-fluid mx-auto"
                                    />
                                </div>
                                <div>
                                    <img
                                        src={ab2}
                                        alt="product"
                                        className="img-fluid mx-auto"
                                    />
                                </div>
                                <div>
                                    <img
                                        src={ab3}
                                        alt="product"
                                        className="img-fluid mx-auto"
                                    />
                                </div>
                                <div>
                                    <img
                                        src={ab4}
                                        alt="product"
                                        className="img-fluid mx-auto"
                                    />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </Col>

            </Row>
        </div></>
    )
}

export default AboutSlider