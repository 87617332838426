import React, { useEffect } from "react";
import "../../../theme/css/Contactus.css";
import { Row, Col } from "react-bootstrap";
import Iframe from "react-iframe";
import Aos from "aos";
import "aos/dist/aos.css";
import ContactUs from "./ContactUs";
export const Contact = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="otherPageBanner contactUsBanner">
        <div className="text-center title" data-aos="fade-down">
          <h2>CONTACT US</h2>
          <h6>Let's solve problems together</h6>
        </div>
      </div>
      <div className="container">
        <div className="contactContain">
          <Row data-aos="flip-down">
            <Col lg={4} md={4} sm={4} xl={4}>
              <div className="contactIconBgShadow text-center">
                <i className="fas fa-search-location"></i>
                <h2>Office</h2>
                <h6>
                  <p>T1 1401</p>
                  <p>Hinjawadi, Pune, 411045</p>
                </h6>
              </div>
            </Col>
            <Col lg={4} md={4} sm={4} xl={4}>
              <div className="contactIconBgShadow text-center">
                <i className="fas fa-headphones-alt"></i>
                <h2> Call us directly</h2>
                <h6>
                  <p>Phone: +91 8623824018</p>
                </h6>
              </div>
            </Col>
            <Col lg={4} md={4} sm={4} xl={4}>
              <div className="contactIconBgShadow text-center">
                <i className="fas fa-envelope-open-text"></i>
                <h2>E-mail us</h2>
                <h6>
                  <p>
                    <a href="mailto:info@emplai.live">info@emplai.live</a>
                  </p>
                </h6>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ContactUs />
      <Iframe
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.8900648147787!2d73.7329138739281!3d18.578994382525888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bbef80719ad7%3A0x65bc085a4aba81f5!2sTower-B1%2C%20Blue%20Ridge%20Approach%20Road%2C%20Blue%20Ridge%20Town%20Pune%2C%20Phase%201%2C%20Hinjawadi%20Rajiv%20Gandhi%20Infotech%20Park%2C%20Hinjawadi%2C%20Pimpri-Chinchwad%2C%20Maharashtra%20411057!5e0!3m2!1sen!2sin!4v1711358420369!5m2!1sen!2sin"
        width="100%"
        height="450px"
        id="myId"
        className="iframeContactUs"
        display="initial"
        position="relative"
      />
    </>
  );
};
