import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./theme/css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import DefultScrollToTop from "../src/components/ScrollToTop/DefultScrollToTop";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from "./AuthProvider";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <DefultScrollToTop />
    <AuthProvider>
    <App />
    </AuthProvider>
    <ToastContainer />
  </BrowserRouter>,

  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
