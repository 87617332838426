import React from 'react'
import '../../../theme/css/Portfolio.css'
import PortfolioImg from '../Portfolio/PortfolioImg';
import { Link } from "react-router-dom";

export default function ScrumMaster() {
    return (
        <>
         <div className="container">
        <div className="banner banner-details">
        <div className="banner-all-details">
          <div className="text-width">
            <h3>DVS Scrum Master</h3>

            <p>AI Powered Scrum Master</p>
          </div>
        </div>
      </div>

     

      <div className="aboutus spacing-1">
        <button className=" button">
          <Link to="https://www.youtube.com/watch?v=jwiiNQRe8PE">
            {" "}
            Watch Demo{" "}
          </Link>
        </button>
      </div>

      <div className="titleFont spacing-1">
        <h3>About DVS Scrum Master</h3>
        <p className="paragraph">
          DVS Scrum Master is an AI-powered digital virtual assistant
          designed to run agile ceremonies for the team, taking actions for blockers and tracking them, updating project management tracker.
          DVS Scrum Master is integrated with common project management trackers like JIRA. DVS Scrum Master is also integrated with outlook, teams,
          google workspace and slack. 


        </p>

        <p className="paragraph">
          DVS Scrum Master aims to provide following value propositions: 


        </p>

        <ul className="paragraph">
            <li>
              {" "}
              Time to Hire - No need to wait for months to get a Scrum Master hired. 
            </li>
            <li>
              {" "}
              Retain the knoeledge - No need to worry about the knowledge retention when the Scrum Master leaves the team. 
            </li>
            <li>
              {" "}
              Streamline the process - Streamline your process with our Digital Scrum Master. 
            </li>
            <li>
              {" "}
              Leave all the administrative work to DVS Scrum Master and Tech Leads / Product Owner focus on the product development. 
            </li>
          </ul>

        </div>

        <div className="titleFont spacing-1" >
          <h3>DVS Scrum Master Key Capabilities </h3>
        </div>
        <PortfolioImg/>
        </div>
      </>
    )
}
