import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import ThemeLogo from "../../assets/Images/BrandLogo/ThemeLogo.png";
import MSHubLogo from "../../assets/Images/BrandLogo/MSHubLogo.png";

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Copen, CsetOpen] = useState(false);
  const [Aopen, AsetOpen] = useState(false);

  return (
    <>
      <div >
        <Container>
          <Row className="Footerbox">
            {/* <Col xl={5} lg={5} md={4} sm={12}>
              <div className="F-col-1 footer-text">
                <img
                  src={MSHubLogo}
                  alt="themeLogo"
                  className="img-fluid mx-auto WLogo"
                />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the dummy text ever
                  since the 1500s.Lorem Ipsum is simply dummy text of the
                  printing.
                </p>
              </div>
            </Col> */}
            {/* <Col xl={2} lg={2} md={2}>
              <div className="F-col-2 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Useful Links
                  <Button
                    className="Footer-button"
                    onClick={() => UsesetOpen(!Useopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Useopen}
                  ></Button>
                </h3>
                <Collapse in={Useopen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/aboutus">About Us</Link>
                    </li>
                    <li>
                      <Link to="/planing">Price List</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Case stories</Link>
                    </li>
                    <li>
                      <Link to="/aboutus">Latest news</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col> */}
            {/* <Col xl={2} lg={2} md={2}>
              <div className="F-col-3 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Services
                  <Button
                    className="Footer-button"
                    onClick={() => CsetOpen(!Copen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Copen}
                  ></Button>
                </h3>
                <Collapse in={Copen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/service">Help</Link>
                    </li>
                    <li>
                      <Link to="/faq">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/planing">Terms of use</Link>
                    </li>
                    <li>
                      <Link to="/contact">Support</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col> */}
          
            {/* <Col xl={3} lg={3} md={4} sm={12}>
              <div className="F-col-4 footer-text FooterVertical ">
                <h3 className="footerLinkHeading">Contact Us 
                <Button
                    className="Footer-button"
                    onClick={() => AsetOpen(!Aopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Aopen}
                  ></Button></h3>
                  <Collapse in={Aopen}>
                <div className="quickContact">
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>
                      T1 1401, Hinjawadi, Pune, 411045, India
                    </span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>+91 8623824018</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope"></i>
                    <span>info@emplai.live</span>
                  </div>
                </div>
                </Collapse>
                <ul className="list-unstyled social-icon">
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fa fa-google"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col> */}
           
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        
        <h6 className="F-copywrite">Copyright © DVSSPL. 2024 . All Rights Reserved</h6>
        <p></p>
        <ul className="list-unstyled social-icon">
                  <li className="d-inline-block">
                    <a href="https://www.facebook.com/profile.php?id=61559105860700" className="d-block">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="https://www.linkedin.com/company/dvs-solutions-tech/" className="d-block">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="https://twitter.com/dvstechpvtltd_" className="d-block">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>

                </ul>
       
      </div>
    </>
  );
};
export default Footer;
