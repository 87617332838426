import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import "../../../theme/css/AboutUs.css";

import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";

const TermsAndConditions = () => {
  return (
    <div className="container">
      <div className="titleFont">
        <h2>Terms and Conditions</h2>

        <h3>1. Introduction</h3>
        <p>
          Welcome to DVSSPL (Digital Virtual Staffing Solutions Pvt Ltd)! As you
          use our services, you agree to be bound by these terms and conditions
          ("Terms"). If you disagree with any part of the terms, you may not
          access our services.
        </p>

        <h3>2. User Eligibility</h3>
        <p>
          Our services are available to users who are 13 years of age or older.
          Users under 18 years old must have parental or guardian approval to
          use our services. By using our services, you confirm that you meet
          these eligibility requirements.
        </p>

        <h3>3. Use of Services</h3>
        <p>
          Our services, including career counseling, are intended for personal,
          non-commercial use. You agree to use our services only for lawful
          purposes and not for any activities that may harm us or others.
        </p>

        <h3>4. AI-Generated Content</h3>
        <p>
          The responses and recommendations provided by our services are
          generated by AI and may sometimes be incorrect. We recommend
          validating any career counseling advice with a certified career
          counselor. Career aspirations may evolve, so we suggest repeating this
          session at least once every two quarters.
        </p>

        <h3>5. Accounts</h3>
        <p>
          When you create an account with us, you must provide accurate and
          complete information. You are responsible for maintaining the
          confidentiality of your account and password and for restricting
          access to your account.
        </p>

        <h3>6. Termination</h3>
        <p>
          We may terminate or suspend access to our services immediately,
          without prior notice or liability, for any reason, including if you
          breach the Terms.
        </p>

        <h3>7. Limitation of Liability</h3>
        <p>
          In no event shall DVSSPL (Digital Virtual Staffing Solutions Pvt Ltd),
          nor its directors, employees, partners, agents, suppliers, or
          affiliates, be liable for any indirect, incidental, special,
          consequential, or punitive damages, including without limitation, loss
          of profits, data, use, goodwill, or other intangible losses, resulting
          from (i) your use or inability to use the service; (ii) any
          unauthorized access to or use of our servers and/or any personal
          information stored therein; (iii) any interruption or cessation of
          transmission to or from our services.
        </p>

        <h3>8. Governing Law</h3>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of DVSSPL, without regard to its conflict of law provisions.
        </p>

        <h3>9. Changes</h3>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. What constitutes a material change will be
          determined at our sole discretion. By continuing to access or use our
          services after those revisions become effective, you agree to be bound
          by the revised terms.
        </p>

        <h3>10. Contact Us</h3>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <p>Email: support@dvs.solutions</p>

        <h3>11. Third-Party Services</h3>
        <p>
          We use third-party services, including Google Sign-On and Azure Cloud,
          to enhance our services. By using our services, you also agree to the
          terms and privacy policies of these third-party providers.
        </p>

        <h3>12. Parental Approval</h3>
        <p>
          Users under the age of 18 must have parental or guardian approval to
          use our services. By using our services, you confirm that such
          approval has been obtained. Our services are intended for career
          counseling purposes and should not be used for any other activities.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
