import React, { useState } from "react";
import { Row, Col, InputGroup, FormControl, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

export default function StartedToday() {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const handleSubscribe = async () => {
        setIsLoading(true);
        try {
            const response = await fetch("https://emplgateway.azurewebsites.net/dvs-service/request/subscribe", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email })
            });
            if (response.ok) {
                console.log("Subscription successful");
                toast.success("Suscribed sucessfully!");
            } else {
                console.error("Failed to subscribe");
                toast.error("Failed to subscribe");
            }
        } catch (error) {
            console.error("Error subscribing:", error);
            toast.error(error.response.data);
        }finally {
            setIsLoading(false);
            setEmail("")
          }
    };

    return (
        <>
            <div className="startedToday">
                <div className="container">
                    <div className="startedTodayMainDivFloat">
                        <Row>
                            <Col xl={3} lg={1} md={1} sm={1}>
                                {/* Your icon and text */}
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} className="startedTodaySideTextCol">
                                {/* Your side text */}
                            </Col>
                            <Col xl={5} lg={7} md={7} sm={7}>
                                <div className="startedEmailBox">
                                    <InputGroup className="">
                                        <FormControl
                                            placeholder="Your Email..."
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            disabled={isLoading} 
                                        />
                                         <button className="button" onClick={handleSubscribe} disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <Spinner animation="border" size="sm" /> Subscribing...
                                                </>
                                            ) : (
                                                "Subscribe Now"
                                            )}
                                        </button>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}
