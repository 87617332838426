import React, { useState } from "react";
import axios from "axios"; // Import axios for making HTTP requests
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormSignup = ({ login }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    password2: ""
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const validateForm = () => {
    let errors = {};

    if (!values.email.trim()) {
      errors.email = "Email required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email address is invalid";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 6) {
      errors.password = "Password needs to be 6 characters or more";
    }

    if (!values.password2) {
      errors.password2 = "Password is required";
    } else if (values.password2 !== values.password) {
      errors.password2 = "Passwords do not match";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await axios.post("http://localhost:8080/api/signup", {
        email: values.email,
        password: values.password,
        // Add other form fields here if needed
      });
      // Handle successful response (if needed)
      toast.success("User created successfully!");
      setValues({ email: "", password: "", password2: "" });
      setErrors({});
      console.log("Form submitted successfully", response.data);
    } catch (error) {
      // Handle error
      toast.error(error.response.data);
      
    }
  };

  return (
    <div className="form-content-right">
      <form onSubmit={handleSubmit} className="form" noValidate>
        <h1 className="SignUpInTitle">Sign Up</h1>
        <h1>Get started with us today!</h1>

        <div className="form-inputs">
          <div className="input-container">
            <i className="fa fa-envelope icon"></i>
            <input
              className="form-input input-field"
              type="email"
              name="email"
              placeholder="Enter your email"
              value={values.email}
              onChange={handleChange}
            />
          </div>
          {errors.email && <p>{errors.email}</p>}
        </div>
        <div className="form-inputs">
          <div className="input-container">
            <i className="fa fa-key icon"></i>
            <input
              className="form-input input-field"
              type="password"
              name="password"
              placeholder="Enter your password"
              value={values.password}
              onChange={handleChange}
            />
          </div>
          {errors.password && <p>{errors.password}</p>}
        </div>
        <div className="form-inputs">
          <div className="input-container">
            <i className="fa fa-check-square icon"></i>
            <input
              className="form-input input-field"
              type="password"
              name="password2"
              placeholder="Confirm your password"
              value={values.password2}
              onChange={handleChange}
            />
          </div>
          {errors.password2 && <p>{errors.password2}</p>}
        </div>
        <button className="form-input-btn" type="submit">
          Sign up
        </button>
        <span className="form-input-login">
          Already have an account? Login{" "}
          <span style={{ color: "#27cdff" }} onClick={login}>
            here
          </span>
        </span>
        <div className="signupSocial">
          <h2> Login with Social Media </h2>
        </div>

        <ul className="list-unstyled social-icon">
          <li className="d-inline-block">
            <a href="/" className="d-block">
              <i className="fab fa-facebook-f"></i>
            </a>
          </li>
          <li className="d-inline-block">
            <a href="/" className="d-block">
              <i className="fa fa-google"></i>
            </a>
          </li>
          <li className="d-inline-block">
            <a href="/" className="d-block">
              <i className="fab fa-twitter"></i>
            </a>
          </li>
          <li className="d-inline-block">
            <a href="/" className="d-block">
              <i className="fab fa-instagram"></i>
            </a>
          </li>
        </ul>
      </form>
      
    </div>
  );
};

export default FormSignup;
