import React from "react";
import Slider from "react-slick";
import { Card, Col } from "react-bootstrap";
import OurBlogData from "./OurBlogData";
import { Link } from "react-router-dom";

export default function OurBlog() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* <div className="container">
        <div className="ourBlog spacing">
          <div className="titleFont">
            <h3>Our Blog</h3>
            <h2>Blogs Coming Soon</h2>
          </div>
          <div>
            <Slider {...settings} className="row">
              {OurBlogData.map((blog) => (
                <Col xl={4}>
                  <div className="ourBlogCard" data-aos="fade-up" key={blog.id}>
                    <Card>
                      <Card.Img
                        className="img-fluid mx-auto"
                        variant="top"
                        src={blog.image}
                      />

                      <Card.Body>
                        <div className="blogCardBody">
                          <div className="d-inline ourBlogContain">
                            <span>{blog.date}</span>
                            <span className="d-inline ourBlogContainIcon">
                              <i className="fa fa-user" aria-hidden="true"></i>
                              {blog.profile}
                            </span>
                          </div>
                          <hr />
                          <Card.Title>
                            <Link to="/planing">{blog.title}</Link>
                          </Card.Title>
                          <Card.Text className="paragraph">
                            {blog.contain}
                          </Card.Text>
                          <button className="button">
                            <Link to="/service"> Read More </Link>
                          </button>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              ))}
              
            </Slider>
          </div>
        </div>
      </div> */}
    </>
  );
}
